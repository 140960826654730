import { Icons, Html5, Cubes } from '@vicons/fa';
import { DesignIdeas16Regular, Javascript16Filled } from '@vicons/fluent';
import {
    react,
    firebase,
    electron,
    linux,
    im,
    seo,
    google,
    wordpress,
    obs,
    audacity,
    premiere,
    afterEffects,
    python,
    html5,
    nodejs,
    nuxt,
    blender,
    sass,
    tailwind,
    typeScript,
    vue,
    unrealEngine,
    bootstrap,
} from '../../assets/SVG_ICONS';

const yearCount = (year) => {
    return new Date().getFullYear() - year;
};

export const skillList = [];

export const mySkills = [
    {
        icon: Html5,
        tech: 'HTML/CSS',
        des: "I started learning HTML and CSS when I was 12 years old, and I've been using it ever since. Out of recently I started to ocassionally use SCSS, SASS, Tailwind, Bootstrap, etc for faster development.",
        yearStarted: 2000,
        tools: [
            {
                icon: tailwind,
                text: 'tailwindcss',
                des: `A utility-first CSS framework packed with classes like flex, pt-4, text-center and rotate-90 that can be composed to build any design, directly in your markup.`,
            },
            {
                icon: bootstrap,
                svg: true,
                text: 'bootstrap',
                des: `Bootstrap is a popular, open-source front-end development framework that utilizes a responsive grid system and pre-designed UI components for streamlined web design and development.`,
            },
            {
                icon: sass,
                text: 'SASS',
                des: `CSS with superpowers. Sass is the most mature, stable, and powerful professional grade CSS extension language in the world.`,
            },
            {
                icon: html5,
                text: 'html5',
                des: `HTML5 is a markup language used for structuring and presenting content on the World Wide Web. It is the fifth and last major HTML version that is a World Wide Web Consortium recommendation. The current specification is known as the HTML Living Standard.`,
            },
        ],
    },
    {
        tech: 'JavaScript',
        icon: Javascript16Filled,
        des: 'I am passionate about JavaScript & TypeScript and its ecosystem. I started loving Vue/Nuxt as frameworks, then I swapped to React & React Native, but I am open to learning more!',
        yearStarted: 2018,
        tools: [
            {
                icon: react,
                text: 'React',
                des: `React & React Native are powerful JavaScript libraries for building user interfaces & mobile apps. Developed by Facebook, it allows developers to create reusable UI components and manage application state efficiently, making it ideal for both single-page and complex web applications. <a href="https://reactjs.org/" target="_blank" alt="Go to React">Go To React</a>`,
            },
            {
                icon: vue,
                text: 'VueJS',
                des: `Vue.js is an open-source model–view–viewmodel front end JavaScript framework for building user interfaces and single-page applications. It was created by Evan You, and is maintained by him and the rest of the active core team members. <a href="https://vuejs.org/" target="_blank" alt="Go To VueJS">Go To VueJS</a>`,
            },
            {
                icon: nuxt,
                text: 'NuxtJS',
                des: `Build your next Vue.js application with confidence using NuxtJS. An open source framework making web development simple and powerful. <a href="https://nuxtjs.org/" target="_blank" alt="go to Nuxtjs">Go To NuxtJS</a>`,
            },
            {
                icon: nodejs,
                text: 'NodeJS',
                des: `Node.js is an open-source, cross-platform, back-end JavaScript runtime environment that runs on the V8 engine and executes JavaScript code outside a web browser.`,
            },
            {
                icon: electron,
                text: 'Electron',
                des: `ElectronJS is a open-source framework that enables the development of cross-platform desktop applications using web technologies such as JavaScript, HTML and CSS. You can build applications for Windows, MacOS, and Linux.`,
            },
            {
                icon: typeScript,
                text: 'TypeScript',
                des: `Its actually like javascript but focuses more on types, this was created to be useful on IDEs like VS Code, and also help lower type errors in the future, specially as the project grows. <a href="https://www.typescriptlang.org/">To Know More Click Here</a>`,
            },
        ],
    },
    {
        icon: Cubes,
        tech: '3D & 3D Scripting',
        des: `In the past ${yearCount(
            2021
        )} years I've had the chance to create a game, game laucher, bunch of 3D stuff, and I've been loving it. I've used Unreal Engine and Blender as 3D tools, and Python to make complex tasks easier, create, save and pass around the data.`,
        yearStarted: 2021,
        tools: [
            {
                icon: unrealEngine,
                svg: true,
                text: 'Unreal Engine',
                des: 'Unreal Engine 5 is a cutting-edge, real-time 3D game development engine that features advanced technologies such as Nanite and Lumen for photorealistic graphics and seamless worlds.',
            },
            {
                icon: blender,
                teny: true,
                text: 'Blender',
                des: 'Blender is a powerful, open-source 3D modeling, animation and rendering software that features advanced capabilities such as physics simulation and motion tracking for professional-grade 3D content creation.',
            },
            {
                icon: python,
                teny: true,
                text: 'Python',
                des: 'Python is a versatile, high-level programming language known for its simple syntax, wide range of libraries, and ability to handle multiple paradigms, making it popular for various types of programming tasks including data science, AI and web development.',
            },
        ],
    },
    {
        icon: Icons,
        tech: 'Video/Sound Creation',
        des: 'Being Youtube + Twitch Partner & having that as my only source of income for 5-6 years, I had to learn how to create & edit sounds and videos, add effects, create trailers, voice overs, and much more. ',
        yearStarted: 2013,
        tools: [
            {
                icon: premiere,
                text: 'Premiere',
                des: 'Adobe Premiere is a professional-grade video editing software that offers a wide range of tools for non-linear video editing, including advanced color correction, audio mixing, and motion graphics capabilities, enabling the creation of high-quality visual content.',
            },
            {
                icon: afterEffects,
                text: 'After Effects',
                des: 'After Effects is a professional-grade motion graphics and visual effects software that offers a wide range of tools for compositing, animation, and motion tracking, enabling the creation of complex visual effects and animations for film, television, and digital media.',
            },
            {
                icon: audacity,
                text: 'Audacity',
                des: 'Audacity is a free, open-source, cross-platform digital audio editor and recording software that offers a wide range of tools for audio editing, mixing, and mastering, including multi-track editing, audio effects, and noise reduction, making it a versatile audio editing tool for both professional and amateur use.',
            },
            {
                icon: obs,
                text: 'OBS Studio',
                des: 'OBS Studio is a free, open-source, cross-platform software for live streaming and video recording, that offers advanced features such as multiple sources, audio and video filters, and scene management, making it a powerful tool for creating professional-quality live streams and videos.',
            },
        ],
    },
    {
        icon: DesignIdeas16Regular,
        tech: 'Various Tools',
        des: `For the past ${yearCount(
            2000
        )} years I've been exploring the depths of the internet, learning new things, and trying to make the world a better place. I have achieved many great things, solved countless challenges, and learned a lot! Worth mentioning: Wordpress web designer (50+ websites - mostly personal), Internet Marketer (SEO (Offsite/Onsite), Ad Strategies, Product Planing, etc), Penetration Testing + Cyber Security (PHLAK, Backtrack, Kali + tools), Sales Person + Sales Instructor on various roles (real estate, insurance, SAAS's, etc), much more.`,
        yearStarted: 2010,
        tools: [
            {
                icon: wordpress,
                text: `WordPress`,
                des: `WordPress is a free and open-source content management system (CMS) based on PHP and MySQL. To function, WordPress has to be installed on a web server, which would either be part of an Internet hosting service or a network host in its own right. <a href="https://wordpress.org/" target="_blank" alt="Go To Wordpress">Go To Wordpress</a>`,
            },
            {
                icon: firebase,
                text: `Firebase`,
                des: `Firebase is a comprehensive mobile and web application development platform that provides a wide range of features and services, including real-time databases, authentication, storage, hosting and machine learning capabilities, making it a one-stop solution for app development and management.`,
            },
            {
                icon: google,
                text: `Google Fu`,
                des: `Google Fu is the art of using Google search patterns skillfully to find the information you need efficiently.`,
            },
            {
                icon: seo,
                text: `SEO`,
                des: `Search Engine Optimization (<i>SEO</i>) is the practice of utilizing various on-page and off-page techniques to improve a website's organic search engine rankings, by increasing visibility, relevance and credibility to target audience. the goal is usually higher organic traffic. `,
            },
            {
                icon: im,
                text: `IM`,
                des: `Internet Marketing is the process of promoting a business or brand and its products or services over the internet using skills & tools that help drive traffic, leads, and sales. Some of the tools include: SEO, Social Media Ad Campaigns, Email Marketing, Content Marketing, etc.`,
            },
            {
                icon: linux,
                text: `PenTesting`,
                des: `Penetration testing is a method of assessing the security of a computer system, network, application, etc, by simulating a cyber-attack, identifying vulnerabilities and providing recommendations for mitigation.`,
            },
        ],
    },
];
